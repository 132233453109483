import React, {useEffect, useState} from "react";
import logo from "../images/LogoDiamou.png";
import {Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import jwtDecode from "jwt-decode";

const Auth = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(0);

    const navigate = useNavigate();

    const handleLogin = async (e) => {

        e.preventDefault();

        let urlProd = "https://diamou-back-production.up.railway.app/login";
        let urlDev = "http://localhost:8085/login";

        try {
            const response = await axios.post(urlProd, { username, password });
            //console.log(response.data);

            const token = response.data.accessToken;

            console.log(token);
            //console.log(jwtDecode(token));


            // Stocker le token dans le localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(jwtDecode(token)));
            navigate('/dashboard');

        } catch (error) {
            console.error('Erreur de connexion', error);
            setError(1);
        }
    };

    const displayError = error ? (
        <div className="alert alert-danger" role="alert">
            Email ou mot de passe incorrecte
        </div>
    ) : null ;

    return (
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6"
             data-sidebartype="full"
             data-sidebar-position="fixed" data-header-position="fixed">
            <div
                className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="row justify-content-center w-100">
                        <div className="col-md-8 col-lg-6 col-xxl-3">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <Link to="/"
                                       className="text-nowrap logo-img text-center d-block py-3 w-100">
                                        <img src={logo} width="180" alt="" />
                                    </Link>
                                    <p className="text-center">Bienvenue sur la plateforme de paiement des serives DIAMOU SARL</p>
                                    {displayError}
                                    <form onSubmit={handleLogin}>
                                        <div className="mb-3">
                                            <label htmlFor="username" className="form-label">Email ou Numéro de téléphone</label>
                                            <input type="text" className="form-control" id="username" name="username"
                                                   aria-describedby="emailHelp" value={username}
                                                   onChange={(e) => setUsername(e.target.value)} />
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="password"
                                                   className="form-label">Mot de passe</label>
                                            <input type="password" className="form-control" id="password" value={password}
                                                   onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                            <div className="form-check">
                                                <input className="form-check-input primary" type="checkbox" value=""
                                                       id="flexCheckChecked" checked />
                                                    <label className="form-check-label text-dark"
                                                           htmlFor="flexCheckChecked">
                                                        Se rappeler de moi.
                                                    </label>
                                            </div>
                                            {/*
                                            <a className="text-primary fw-bold" href="./index.html">Mot de passe oublier
                                                ?</a>
                                            */}
                                        </div>
                                        <button className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2" type="submit">
                                            Connexion
                                        </button>
                                        {/*
                                        <div className="d-flex align-items-center justify-content-center">
                                            <p className="fs-4 mb-0 fw-bold">New to Modernize?</p>
                                            <a className="text-primary fw-bold ms-2"
                                               href="./authentication-register.html">Create an account</a>
                                        </div>
                                        */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Auth;