import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';
import "./assets/css/styles.css";
import "./assets/css/styles.min.css";
import Dashboard from "./components/Dashboard";
import AddInvoice from "./components/AddInvoice";
import Listing from "./components/Listing";
import Auth from "./components/Auth";
import PrivateRoutes from "./routes/PrivateRoutes";
import Research from "./components/Research";

const App = () => {

    const isAuthenticated = localStorage.getItem('token') !== null;

    return (
        <Router>
            <Routes>
                <Route element={<PrivateRoutes />}>
                    <Route element={<Dashboard/>} path="/dashboard" exact/>
                    <Route element={<AddInvoice/>} path="/ajouter-facture" exact/>
                    <Route element={<Listing/>} path="/liste-des-factures" exact/>
                    <Route element={<Research/>} path="/status-de-paiment" exact/>
                </Route>
                { isAuthenticated ? <Route element={<Dashboard/>} path="/dashboard" exact/> : <Route element={<Auth/>} path="/" /> }
                {/* Autres routes publiques */}
            </Routes>
        </Router>
    );
};

export default App;
