import React, {useState} from "react";
import Sidebar from "./Sidebar";
import {useNavigate} from "react-router-dom";
import axios from "axios";

const Research = () => {

    const [cardId, setCardId] = useState('');

    const [status, setStatus] = useState('');
    const [montant, setMontant] = useState(0);
    const [orderId, setOrderId] = useState('');
    const [reference, setReference] = useState('');

    const margin = {
        marginLeft: "400px",
        width: "70%"
    };

    const navigate = useNavigate();

    const deconnexion = () => {
        localStorage.clear();
        navigate('/');
    }

    const getStatus = () => {

        const headers = {
            'Content-Type': 'application/json', // Définir le type de contenu comme JSON
            'Authorization': 'Bearer 4ef286af-830f-37a8-b151-30e0075bccb0',
            'country-code': "ci",
            'category': 'BIZAO',
            'lang':'fr'
            // Ajouter d'autres en-têtes si nécessaire
            // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
        };

        const url = `https://preproduction-gateway.bizao.com/debitCard/v2/getStatus/${cardId}`;

        axios.get(url, { headers })
            .then(response => {
                console.log(response.data);
                setStatus(response.data.status);
                setMontant(response.data.amount);
                setReference(response.data.reference);
                //setOrderId();
            })
            .catch(error => {
                console.log(error);
            });
    };



    return (
        <div>
            <Sidebar/>
            <div style={margin}>
                <header className="app-header">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item d-block d-xl-none">
                                <a className="nav-link sidebartoggler nav-icon-hover" id="headerCollapse"
                                   href="javascript:void(0)">
                                    <i className="ti ti-menu-2"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                Hello {JSON.parse(localStorage.getItem('user')).email} !
                            </li>
                        </ul>
                        <div className="navbar-collapse justify-content-end px-0" id="navbarNav">
                            <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                <button className="btn btn-danger" onClick={deconnexion}>Deconnexion</button>
                            </ul>
                        </div>
                    </nav>
                </header>

                <h1>Statut de paiement</h1>
                <form
                    className="d-flex align-items-start"
                    onSubmit={(e) => {
                        e.preventDefault();
                        getStatus();
                    }}
                >
                    <div className="mb-3 flex-grow-1">
                        <input
                            type="text"
                            placeholder="Rechercher de facture par identifiant Ex: DIAMOU_CARD_TEST_V00 "
                            name="search"
                            className="form-control"
                            id="search"
                            aria-describedby="exampleInputName"
                            value={cardId}
                            onChange={(e) => setCardId(e.target.value)}
                        />
                    </div>
                    <button className="btn btn-primary ms-2">Rechercher</button>
                </form>
                <div>
                    <div className="row">
                        <div className="col-lg-12 d-flex align-items-stretch">
                            <div className="card w-100">
                                <div className="card-body p-4">
                                    <h5 className="card-title fw-semibold mb-4">Factures récemment ajoutées</h5>
                                    <div className="table-responsive">
                                        <table className="table text-nowrap mb-0 align-middle">
                                            <thead className="text-dark fs-4">
                                            <tr>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Reference</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Montant</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Status</h6>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <td className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0 fs-4">{reference}</h6>
                                                </td>
                                                <td className="border-bottom-0">
                                                    <p className="mb-0 fw-normal">{montant} FCFA</p>
                                                </td>
                                                <div className="d-flex align-items-center gap-2">
                                                    <span className="badge bg-success rounded-3 fw-semibold">
                                                        {status}
                                                    </span>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </div>
    );

}

export default Research;