// PrivateRoutes.js
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoutes = () => {
    const isAuthenticated = localStorage.getItem('token') !== null;

    return (
        isAuthenticated ? <Outlet/> : <Navigate to="/"/>
    );
};

export default PrivateRoutes;
