import React, {useEffect, useState} from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
const Listing = () => {

    const margin = {
        marginLeft: "400px",
        width: "70%"
    };

    const [data, setData] = useState([]); // Modifier ici pour initialiser à un tableau vide
    //const [loading, setLoading] = useState(true);


    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10); // Taille de la page
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const [filteredData, setFilteredData] = useState(data);

    const urlPaymentDev = 'https://preproduction-gateway.bizao.com/debitCard/v2';
    const urlPaymentProd = 'https://api.bizao.com/debitCard/v2';


    // Fonction pour formater la date
    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, "0");
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const fetchData = async () => {

        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        };

        let urlProd = `https://diamou-back-production.up.railway.app/api/v1/findOneInvoiceByEmail?email=${JSON.parse(localStorage.getItem('user')).email}&page=${currentPage}&size=${pageSize}`;
        let urlDev = `http://localhost:8085/api/v1/findOneInvoiceByEmail?email=${JSON.parse(localStorage.getItem('user')).email}&page=${currentPage}&size=${pageSize}`;

        if (searchTerm) {
            urlProd += `&nameClient=${searchTerm}`;
            //urlDev += `&nameClient=${searchTerm}`;
        }

        try {
            //const response = await axios.get("http://45.155.37.111:8055/api/v1/findAllFactures");
            const response = await axios.get(urlProd, {headers});

            setData(response.data['content']);
            setTotalPages(response.data.totalPages);

            //Remplacez l'URL par l'adresse de votre API
            //console.log(response.data['content']);

            //setLoading(false);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
            //setLoading(false);
        }
    };

    useEffect(() => {
        // Fonction pour charger les données depuis l'API

        fetchData();


    }, [currentPage, pageSize, searchTerm]);

    useEffect(() => {
        console.log(data);// Cela affichera les données à chaque fois que l'état data change
        console.log(totalPages);

        const filtered = data.filter(item =>
            item.nomClient.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);

    }, [data, searchTerm]);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(0); // Réinitialiser la page lors de la recherche
    };

    const handleUpdateSuccess = () => {
        fetchData(); // Met à jour les données après une mise à jour réussie
    };

    // Pour la redirection
    const navigate = useNavigate();

    const handleUpdateStatus = (id) => {

        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        };

        // Effectuer une autre requête POST pour mettre à jour le statut
        const updateStatusData = {
            // Remplacez par les données appropriées nécessaires pour la mise à jour
            "user": "user"
        };

        const urlDevDiamou = `http://localhost:8085/api/v1/updateStatus?id=${id}`;
        const urlProdDiamou = `https://diamou-back-production.up.railway.app/api/v1/updateStatus?id=${id}`;

        axios.post(urlProdDiamou, updateStatusData, { headers })
            .then((updateResponse) => {
                console.log('Statut mis à jour avec succès:', updateResponse.data);
            })
            .catch((updateError) => {
                console.error('Erreur de mise à jour de statut:', updateError);
            });

    }

    const handlePostRequest = (id) => {

        const reference = "DIAMOU_SERVICE";
        const currency = "XOF";
        //const return_url = "http://www.notif_merchant.com/bizao";
        const return_url = "http://google.com";
        //const cancel_url = "https://google.lk";
        const cancel_url = "http://google.com";
        const state = "test";

        // Récupérer les données spécifiques de la ligne en fonction de l'ID
        const rowData = data.find((item) => item.id === id);

        // Créer l'objet de données à envoyer dans la requête POST
        const postData = {
            order_id: rowData.orderId,
            reference: reference,
            amount: rowData.montant,
            currency: currency,
            return_url: return_url,
            cancel_url: cancel_url,
            state: state,
            // Autres données si nécessaire
        };

        //console.log("POSTDATA", postData);


        // Définir les en-têtes de la requête
        const headers = {
            'Content-Type': 'application/json', // Définir le type de contenu comme JSON
            'Authorization': 'Bearer d87ce8f7-c64b-3928-9c48-041c82ca4311',
            'country-code': "ci",
            'category': 'BIZAO',
            'lang':'fr'
            // Ajouter d'autres en-têtes si nécessaire
            // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
        };


        // Effectuer la requête POST vers le serveur en utilisant axios avec les en-têtes
        axios.post(urlPaymentProd, postData, { headers })
            .then((response) => {
                // Traiter la réponse du serveur si nécessaire
                //console.log(response.data);

                const redirectToExternalSite = (url) => {
                    window.open(url, '_blank');
                };

                if(response.data.payment_url){
                    //navigate(response.data.payment_url);
                    handleUpdateStatus(id);
                    redirectToExternalSite(response.data.payment_url);
                    //redirectToExternalSite('https://www.google.fr/');

                }

            })
            .catch((error) => {
                // Gérer les erreurs de requête
                console.error('Erreur de requête POST:', error);
            });

    };

    const deconnexion = () => {
        localStorage.clear();
        navigate('/');
    }


    return (
        <div>
            <Sidebar/>
            <div style={margin}>
                <header className="app-header">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item d-block d-xl-none">
                                <a className="nav-link sidebartoggler nav-icon-hover" id="headerCollapse"
                                   href="javascript:void(0)">
                                    <i className="ti ti-menu-2"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                Hello {JSON.parse(localStorage.getItem('user')).email} !
                            </li>
                        </ul>
                        <div className="navbar-collapse justify-content-end px-0" id="navbarNav">
                            <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                <button className="btn btn-danger" onClick={deconnexion}>Deconnexion</button>
                            </ul>
                        </div>
                    </nav>
                </header>

                <h1>Listes des factures</h1>
                <form className="d-flex align-items-start">
                    <div className="mb-3 flex-grow-1">
                        <input
                            type="text"
                            placeholder="Rechercher de facture par nom"
                            name="nomClient"
                            className="form-control"
                            id="nomClient"
                            aria-describedby="exampleInputName"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <button className="btn btn-primary ms-2">Rechercher</button>
                </form>
                <div>
                    <div className="row">
                        <div className="col-lg-12 d-flex align-items-stretch">
                            <div className="card w-100">
                                <div className="card-body p-4">
                                    <h5 className="card-title fw-semibold mb-4">Factures récemment ajoutées</h5>
                                    <div className="table-responsive">
                                        <table className="table text-nowrap mb-0 align-middle">
                                            <thead className="text-dark fs-4">
                                            <tr>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Nom Client</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Description</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Order ID</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Montant</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Status</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Action</h6>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {filteredData.map(item => (
                                                        <tr key={item.id}>
                                                            <td className="border-bottom-0">
                                                                <p className="mb-0 fw-normal">{item.nomClient}</p>
                                                            </td>
                                                            <td className="border-bottom-0">
                                                                <h6 className="fw-semibold mb-1">{item.descriptionFacture}</h6>
                                                                <span className="fw-normal">{formatDate(item.dateAdded)}</span>
                                                            </td>
                                                            <td className="border-bottom-0">
                                                                <p className="mb-0 fw-normal">{item.orderId}</p>
                                                            </td>
                                                            <td className="border-bottom-0">
                                                                <h6 className="fw-semibold mb-0 fs-4">{item.montant} FCFA</h6>
                                                            </td>
                                                            {item.status === false ? (
                                                                <div className="d-flex align-items-center gap-2">
                                                                    <span className="badge bg-success rounded-3 fw-semibold">
                                                                        non ouvert
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div className="d-flex align-items-center gap-2">
                                                                    <span className="badge bg-danger rounded-3 fw-semibold">
                                                                        ouvert
                                                                    </span>
                                                                </div>
                                                            )}
                                                            <td className="border-bottom-0">
                                                                <div className="d-flex align-items-center gap-2">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() => {
                                                                            handlePostRequest(item.id);
                                                                        }
                                                                    }>Payer</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        className="btn btn-primary m-2"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 0}
                    >
                        Page précédente
                    </button>

                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageClick(index)}
                            className={`btn btn-primary m-1 ${currentPage === index ? 'active' : ''}`}
                        >
                            {index + 1}
                        </button>
                    ))}

                    <button
                        className="btn btn-primary m-2"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages - 1}
                    >
                        Page suivante
                    </button>
                </div>
            </div>
        </div>
    );

}

export default Listing;