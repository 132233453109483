import React, {useEffect, useState} from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import {useNavigate} from "react-router-dom";
const Dashboard = () => {

    const margin = {
        marginLeft: "400px",
        width: "70%",
    };

    const center = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "space-between"
    };

    const [data, setData] = useState([]); // Modifier ici pour initialiser à un tableau vide
    const [loading, setLoading] = useState(true);
    const [totalBill, setTotalBill] = useState(0);
    const [totalBillAttempt, setTotalBillAttempt] = useState(0);

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);

    const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    //nom utilisateur
    const [username, setUsername] = useState('');

    // Fonction pour formater la date
    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, "0");
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    };
    useEffect(() => {
        // Fonction pour charger les données depuis l'API

        if(localStorage.getItem('user')){
            const user = JSON.parse(localStorage.getItem('user'));
            setUsername(user.email);
            //console.log(user);
        }

        const fetchData = async () => {

            //let urlProd = "https://diamou-back-production.up.railway.app/api/v1/findLastFiveInDescendingOrder";
            //let urlDev = "http://localhost:8085/api/v1/findLastFiveInDescendingOrder";

            let urlProd = `https://diamou-back-production.up.railway.app/api/v1/findOneInvoiceByEmail?email=${JSON.parse(localStorage.getItem('user')).email}&page=${currentPage}&size=${pageSize}`;
            let urlDev = `http://localhost:8085/api/v1/findOneInvoiceByEmail?email=${JSON.parse(localStorage.getItem('user')).email}&page=${currentPage}&size=${pageSize}`;

            try {
                const response = await axios.get(urlProd, {headers});

                // Remplacez l'URL par l'adresse de votre API
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
                setLoading(false);
            }
        };

        const totalbills = async () => {

            let urlProd = `https://diamou-back-production.up.railway.app/api/v1/countTotalFactures?email=${JSON.parse(localStorage.getItem('user')).email}`;
            let urlDev = `http://localhost:8085/api/v1/countTotalFactures?email=${JSON.parse(localStorage.getItem('user')).email}`;

            try {
                const response = await axios.get(urlProd, {headers});

                // Remplacez l'URL par l'adresse de votre API
                setTotalBill(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
                setLoading(false);
            }

        }

        const countTotalFacturesAttempt = async () => {

            let urlProd = `https://diamou-back-production.up.railway.app/api/v1/countTotalFacturesAttempt?email=${JSON.parse(localStorage.getItem('user')).email}`;
            let urlDev = `http://localhost:8085/api/v1/countTotalFacturesAttempt?email=${JSON.parse(localStorage.getItem('user')).email}`;

            try {
                const response = await axios.get(urlProd, {headers});

                // Remplacez l'URL par l'adresse de votre API
                setTotalBillAttempt(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
                setLoading(false);
            }

        }

        const getUserByEmail = async () => {

            let urlProd = "https://diamou-back-production.up.railway.app/api/v1/getUserByEmail?email="+JSON.parse(localStorage.getItem('user')).email;
            let urlDev = "http://localhost:8085/api/v1/getUserByEmail?email="+JSON.parse(localStorage.getItem('user')).email;

            try {
                const response = await axios.get(urlProd, {headers});

                // Remplacez l'URL par l'adresse de votre API
                console.log("INFO USER", response.data["content"][0]);

                setLoading(false);
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
                setLoading(false);
            }

        }

        fetchData();
        totalbills();
        getUserByEmail();
        countTotalFacturesAttempt();
        //console.log(totalBill);

    }, []);

    const navigate = useNavigate();

    const deconnexion = () => {
        localStorage.clear();
        navigate('/');
    }

    return (
        <div>
            <Sidebar/>
            <div style={margin}>
                <header className="app-header">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item d-block d-xl-none">
                                <a className="nav-link sidebartoggler nav-icon-hover" id="headerCollapse"
                                   href="javascript:void(0)">
                                    <i className="ti ti-menu-2"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                Hello {JSON.parse(localStorage.getItem('user')).email} !
                            </li>
                        </ul>
                        <div className="navbar-collapse justify-content-end px-0" id="navbarNav">
                            <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                <button className="btn btn-danger" onClick={deconnexion}>Deconnexion</button>
                            </ul>
                        </div>
                    </nav>
                </header>

                <h1>Dashboard</h1>
                <div className="container">
                    <div className="row" style={{marginTop: "30px"}}>
                        <div className="col-md-6">
                            {/* style={{width: "350px"}} */}
                            <div className="card overflow-hidden" >
                                <div className="card-body p-4">
                                    <h1 className="card-title mb-9 fw-semibold">Total de factures</h1>
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <h4 className="fw-semibold mb-3">{totalBill}</h4>
                                            {/*<div className="d-flex align-items-center mb-3">
                                                  <span
                                                      className="me-1 rounded-circle bg-light-success round-20 d-flex align-items-center justify-content-center">
                                                    <i className="ti ti-arrow-up-left text-success"></i>
                                                  </span>
                                                <p className="text-dark me-1 fs-3 mb-0">+9%</p>
                                                <p className="fs-3 mb-0">last year</p>
                                            </div>*/}
                                            <div className="d-flex align-items-center">
                                                <div className="me-4">
                                            <span
                                                className="round-8 bg-primary rounded-circle me-2 d-inline-block"></span>
                                                    <span className="fs-2">2023</span>
                                                </div>
                                                <div>
                                            <span
                                                className="round-8 bg-light-primary rounded-circle me-2 d-inline-block"></span>
                                                    <span className="fs-2">2023</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="d-flex justify-content-center">
                                                <div id="breakup"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {/* style={{width: "350px"}} */}
                            <div className="card overflow-hidden" >
                                <div className="card-body p-4">
                                    <h5 className="card-title mb-9 fw-semibold">Total des factures en attente</h5>
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <h4 className="fw-semibold mb-3">{totalBillAttempt}</h4>
                                            {/*<div className="d-flex align-items-center mb-3">
                                          <span
                                              className="me-1 rounded-circle bg-light-success round-20 d-flex align-items-center justify-content-center">
                                            <i className="ti ti-arrow-up-left text-success"></i>
                                          </span>
                                                <p className="text-dark me-1 fs-3 mb-0">+9%</p>
                                                <p className="fs-3 mb-0">last year</p>
                                            </div>*/}
                                            <div className="d-flex align-items-center">
                                                <div className="me-4">
                                            <span
                                                className="round-8 bg-primary rounded-circle me-2 d-inline-block"></span>
                                                    <span className="fs-2">2023</span>
                                                </div>
                                                <div>
                                            <span
                                                className="round-8 bg-light-primary rounded-circle me-2 d-inline-block"></span>
                                                    <span className="fs-2">2023</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="d-flex justify-content-center">
                                                <div id="breakup"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="col-lg-4">
                            <div className="card overflow-hidden" style={{width: "350px"}}>
                                <div className="card-body p-4">
                                    <h5 className="card-title mb-9 fw-semibold">Total des factures payées</h5>
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <h4 className="fw-semibold mb-3">0</h4>
                                            <div className="d-flex align-items-center mb-3">
                                          <span
                                              className="me-1 rounded-circle bg-light-success round-20 d-flex align-items-center justify-content-center">
                                            <i className="ti ti-arrow-up-left text-success"></i>
                                          </span>
                                                <p className="text-dark me-1 fs-3 mb-0">+9%</p>
                                                <p className="fs-3 mb-0">last year</p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="me-4">
                                            <span
                                                className="round-8 bg-primary rounded-circle me-2 d-inline-block"></span>
                                                    <span className="fs-2">2023</span>
                                                </div>
                                                <div>
                                            <span
                                                className="round-8 bg-light-primary rounded-circle me-2 d-inline-block"></span>
                                                    <span className="fs-2">2023</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="d-flex justify-content-center">
                                                <div id="breakup"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>*/}

                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col-lg-12 d-flex align-items-stretch">
                            <div className="card w-100">
                                <div className="card-body p-4">
                                    <h5 className="card-title fw-semibold mb-4">Factures récemment ajoutées</h5>
                                    <div className="table-responsive">
                                        <table className="table text-nowrap mb-0 align-middle">
                                            <thead className="text-dark fs-4">
                                            <tr>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Nom Client</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Description</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Status</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Montant</h6>
                                                </th>
                                                {/*
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Status</h6>
                                                </th>
                                                */}
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {data && data['content'] && Array.isArray(data['content']) ? (
                                                    data['content']
                                                        .slice()
                                                        .map((item) => (
                                                            <tr key={item.id}>
                                                                <td className="border-bottom-0">
                                                                    <p className="mb-0 fw-normal">{item.nomClient}</p>
                                                                </td>
                                                                <td className="border-bottom-0">
                                                                    <h6 className="fw-semibold mb-1">{item.intituleFacture}</h6>
                                                                    <span className="fw-normal">{formatDate(item.dateAdded)}</span>
                                                                </td>
                                                                {item.status === false ? (
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <span className="badge bg-success rounded-3 fw-semibold">
                                                                            non ouvert
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <span className="badge bg-danger rounded-3 fw-semibold">
                                                                            ouvert
                                                                        </span>
                                                                    </div>
                                                                )}
                                                                <td className="border-bottom-0">
                                                                    <h6 className="fw-semibold mb-0 fs-4">{item.montant} FCFA</h6>
                                                                </td>
                                                                {/*
                                                                <td className="border-bottom-0">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <span className="badge bg-primary rounded-3 fw-semibold">Low</span>
                                                                    </div>
                                                                </td>
                                                                */}
                                                            </tr>
                                                        ))
                                                ) : (
                                                            <tr>
                                                                <td colSpan="4" className="text-center">
                                                                    Pas de données disponibles.
                                                                </td>
                                                            </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;